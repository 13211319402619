body{
  overflow-x:hidden;
  background-color: #0533FF; /*rgb(198, 222, 255);
  /* background-image: url("../public/bricks.avif"); */
}

.App {
  text-align: center;
  width:100%;
  /* height:100%; */
}

.homeScreen{
  width: calc(100% - 36px);
flex-basis: 1200px;
  padding: 18px;
  /* background-color: green; */
  position:relative;
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
  flex-direction: row;
  justify-content:space-around;
  align-self: center;
}

.hs5{
  flex-basis: 500px;
  align-self: center;
  /* background-color: green; */
  /* height:300px; */
  display:flex;
  flex-grow: 1;
  position:relative;
  border-radius: 20px;
  overflow: hidden;
  padding:20;
}

.hs7{
  flex-basis: 600px;
  align-self: center;
  /* background-color: green; */
  /* height:300px; */
  display:flex;
  flex-grow: 1;
  position:relative;
  border-radius: 20px;
  overflow: hidden;
}

.hs1{
  /* flex-basis: 500px; */
  align-self: center;
  /* background-color: green; */
  /* height:300px; */
  display:flex;
  flex-grow: 1;
  flex-basis: 100%;
  position:relative;
  justify-content: center;
}

.section1{
  color: yellow;
  font-size: 63px;
  font-family: "Balsamiq Sans", sans-serif;
}

.tokenomics{
  /* flex-basis: 300px; */
  align-self: center;
  /* background-color: green; */
  /* height:300px; */
  display:flex;
  flex-grow: 1;
  position:relative;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 25px;
  width:100%;
}

/* .App{
  position:absolute;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game-card:hover {
  transform: scale(1.05);
}

.game-info {
  margin-bottom: 1rem;
}

.game-info p {
  margin: 0.5rem 0;
}

.details-button {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 100%;
}

.details-button:hover {
  background-color: #0056b3;
}

#gameboard{
  /* height:700px;
  width: 811px; */
  /* background-color:#0533FF; */
  /* margin:auto; */
  /* left: calc(50vw - 405.5px);
  position: absolute;
  bottom: 30px; */
  /*margin-top: 40px; calc((100vh - 74px - 700px)/2);*/
  display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-basis: 750px;
    flex-direction: row;
    position: relative;
    /* background-color: pink; */
    gap: 1vw;
    /* flex-grow: .8; */
}

.animated_home{
  position: relative;
  margin: auto;
  /* background-color: green; */
  width:510px;
  margin-bottom:10px;
  /* text-align:center; */
  height: 150px;
  /* top:200px; */
}

.logo_gameboard{
  /* position:absolute; */
  /* left:300px; */
  /* margin:auto;
  top:15px;
  width:280px;
  height:100px; */
  /* background-color:green; */
  /* position: absolute; */
  position: relative;
  margin: auto;
  top: 5px;
  margin-bottom: 5px;
  /* height: 8vh; */
  height: 75px;
  /* width: 100%; */
  display: flex;
  text-align: center;
  justify-content: center;
}

.logo_app_home{
  position:relative;
  /* left:300px; */
  display: flex;
  flex-direction: column;
  /* margin:auto; */
  /* top:190px; */
  flex-basis:1010px;
  /* height:300px; */
  color: yellow;
  text-decoration: none;
  font-family: "Balsamiq Sans", sans-serif;
  /* background-color:yellow; */
  justify-content: center;
}

.gameHomeLogoDiv {
  display: flex;
  flex-direction: row;
  /* flex-basis: 50px; */
  overflow: hidden;
  justify-content: center;
  cursor: pointer;
}

.onlineDiv {
  display: flex;
  flex-direction: row;
  /* flex-basis: 50px; */
  overflow: hidden;
  justify-content: center;
}

.onlineBlack{
  background-color: black; 
  color: white; 
  position: relative; 
  /* z-index: 1001;  */
  left: 20vw;
  width: 16vw;
  height: 2.5vw;
  font-size: 2vw;
  border-radius: 3vw;
  /*marginRight: 180, marginTop: -10*/
}

.onlineGreen{
  background-color: green;
  width: 1.7vw;
  height: 1.7vw;
  border-radius: 12px;
  position: absolute;
  top: .4vw;
  left: .5vw;
}

.gameHomeLogo {
  width:60%;
}

.gameHomeFooter{
  position:absolute;
  /* right: 0px; */
  bottom: 0px;
  margin:auto;
  font-size: 42px;
  color: yellow !important;
  padding:18px;
  cursor:pointer;
  text-decoration: none !important;
}

.gameHomeFooterIcon:hover {
  color: red;
  text-decoration: none !important;
  /* color:yellow; */
}

.socialsMobileContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.noDecs {
  text-decoration: none;
  color: yellow;
}

.gamespace{
  /* height:100px;
  width: 100px;
  float:left;
  margin-left: 14px;
  border-radius:50px;
  margin-top: 14px; */
  display: flex;
  flex: 1 0 12%;
  aspect-ratio: 1;
  border-radius: 50%;
}

.gamespaceCover{
  /* height:80px;
  width:80px;
  float:left;
  margin-left: 10px;
  border-radius:40px;
  margin-top: 10px; */
  width: 80%;
  height:80%;
  margin: 10%;
  border-radius: 50%;
}

.spaceColor0 {
  background-color:white;
}

.spaceColor1 {
  background-color:yellow;
}

.spaceCover1 {
  background-color:rgb(236, 236, 13);
}

.spaceColor2 {
  background-color:red;
}

.spaceCover2 {
  background-color:rgb(235, 11, 11);
}

.selectColumn {
  height: 100%;
  /* width: 13%;
  margin-left: calc(1vw - 2px); */
  /* padding: 0px 4vw; */
  /* position: relative; */
  /* float: left; */
  /* top: 0px; */
  opacity: 0;
  border: none;
  display:flex;
  flex: 1 0 12%;
  background-color: green;
}

.selectColumn:hover {
  opacity: 0.5;
  cursor:pointer;
}

.selectColumnContainer{
  position: absolute;
  width:100%;
  height:100%;
  /* background-color: yellow; */
  left:0px;
  top:0px;
  display:flex;
  justify-content: center;
  flex-direction: row;
  gap: 1vw;
}


/* .App { */
  /* text-align: center; */
  /* background-color: #282c34; */
  /* min-height: calc(100vh - 60px); */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 1vmin); */
  /* color: white; */
  /* align-items: flex-end; */
  /* flex-wrap: wrap; */
  /* gap: 16px; */
/* } */

/* .App-header { */
  /* margin-top: 50px; */
/* } */

.navbar {
  /* display: flex; */
  position:fixed;
  background-color: #0533FF;
  padding: 15px 0px;
  /* flex-grow: 1; */
  /* flex-basis: 1060px; */
  height:40px;
  width:100%;
  z-index:10;
  border-bottom: #0533FF solid 2px;
  text-decoration: none;
  font-family: "Balsamiq Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.logo {
  font-size:40px;
  color: yellow;
  float:left;
  margin-left:30px;
  text-decoration: none;
  font-family: "Balsamiq Sans", sans-serif;
}

.menuElement{
  /* background-color: yellow; */
  /* width:140px; */
  height:40px;
  float:right;
  border-radius:25px;
  cursor: pointer;
  font-size:21px;
  text-decoration: none;
  font-weight: 900;
  margin-right:20px;
  line-height: 44px;
  color:yellow;
}

.menuElement:hover{
  /* font-weight:10000; */
  color:red;
  font-size:24px;
}

.newButtonsContainer{
  position:absolute;
  top:390px;
  left: 0px;
  width:100%;
  /* height: 500px; */
  /* background-color: green; */
}

.newGameButton {
  background-color: rgba(1, 1, 1, 0);
  border: none;
  color: yellow;
  font-size: 8vw;
  font-weight: 900;
  float:left;
  margin-top: 1vw;
  /* height:100px; */
  /* width:500px; */
  /* background-color: pink; */
  position:relative;
  /* position:absolute; */
  /* top:300px; */
  /* left: calc(50% - 250px); */
  cursor:pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: "Balsamiq Sans", sans-serif;
}

a {
  cursor: pointer;
}

@media screen and (min-width: 850px) {
  .newGameButton {
     font-size: 63px;
  }
  .newGameImage:hover{
    width:100px;
  }
}

@media screen and (min-width: 1000px) {
  .onlineBlack{
    left: 200px;
    width: 160px; 
    height: 25px; 
    font-size: 20px; 
    border-radius: 18px; 
  }

  .onlineGreen{
    background-color: green;
    width: 18px;
    height: 18px;
    border-radius: 12px;
    position: absolute;
    top: 3.5px;
    left: 6px;
  }
  .leaderboard-container{
    font-size: 30px !important;
    td{
      padding: 10px 20px !important;
      .copyable{
        svg{
          font-size: 1vw;
        }
      }
    }
  }
}

.newGameButton:hover{
  color: red;
  /* font-size: 11vw; */
}

.connectButtonWrapper{
  position: absolute;
  /* background-color:pink; */
  top:20px;
  right:20px;
  z-index: 101;
}

.homeScreenSection{
  /* background-color: pink; */
  /* border: black 1px solid; */
  position: relative;
  float:left;
  height:540px;
  width:100%;
}

.gameHomeScreen{
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: #0533FF; */
}

.launchAppButton {
  border: yellow 1px solid;
  width:160px;
  font-size:20px;
  line-height: 41px;
}

.launchAppButton:hover{
  border: red 1px solid;
  font-size:23px;
  /* padding:1px; */
}

.ball{
  position: absolute;
  float:"left";
}

.yellow{
  background-color: yellow;
}

.red{
  background-color: red;
}

.black{
  background-color: grey;
}

.red_color{
  color: red !important;
}

.profileUsernameSmall{
  display:flex;
  flex-direction: row;
  justify-content: center;
}

.greenCircleGameboard{
  border-radius: 100%;
  background-color: green;
  justify-self: center;
  display: flex;
  margin-right: 5px;
  position: relative;
  aspect-ratio: 1;
}

.find4AnimationContainer{
  /* background-color:pink; */
  height:300px;
  width:730px;
  left:calc(50% - 359px);
  top:150px;
  position:absolute;
}

#gameboard-body{
  /* position:absolute; */
  /* background-color: green; */
  /* width:100%;
  height:100%;
  top:0px;
  left:0px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4vw;
  padding: 0px 2vw;
}

@media screen and (max-width: 900px) {
  #gameboard-body{
    /* flex-direction: column; */
    width:60%;
    left: 18%;
    position: relative;
  }
   .profileContainer{
    display:none !important;
   }
   .logo_gameboard{
    justify-content: left !important;
    height:50px !important;
    top:15px !important;
    left:15px !important;
    position: absolute !important;
   }
   #gbbc{
    /* margin-top: 30px; */
    gap: 3vh;
   }
  }

  @media screen and (max-width: 589px) {
   .existingGamesContainer{
    max-height:30vh;
   }
  }

  @media screen and (min-width: 590px) {
    .existingGamesContainer{
     max-height:26vh;
    }
  }

@media screen and (min-width: 900px) {
  .profileSmaller{
    display:none !important;
  }
}

@media screen and (max-width: 500px) {
  /* .profileSmaller{
    font-size: 27px !important;
  }
  .profileAddySmall{
    font-size: 24px !important;
  } */
  #gameboard-body{
    width: 80% !important;
    left: 9%;
  }
}

#gbbc{
    width: 100%;
    height: calc(100vh - 80px);
    /* top:100px; */
    position: absolute;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: orange; */
}

.profileSmaller{
  display:flex;
  flex-direction: row;
  justify-content: center;
  color: yellow;
  font-size: 27px;
  font-weight: 900;
  /* background-color: pink; */
  gap: 3vw;
}

.profileAddySmall {
  position: relative;
  font-size: 21px;
}

.redBorder{
  border: 5px red solid;
}

.yellowBorder{
  border: 5px yellow solid;
}

.profilePicSmall{
  /* height:12vw !important;
  width:12vw !important; */
  border-radius: 50%;
  height: 80px;
  width: 80px;
 }

 .profilePointsSmall{
    display: flex;
    flex-direction: column;
    justify-content: center;
 }

.profilePic {
  width: 170px;
  height: 170px;
  /* background-color:green; */
  position:relative;
  top:10px;
  /* left:0px; */
  border-radius: 110px;
  /* margin-top:8px; */
}

.profileContainer {
  /* background-color:pink; */
  /* width:300px;
  height:300px; */
  /* position:relative; */
  /* top:200px;
  margin:auto; */
  /* margin-left: 1000px; */
  /* left: calc(50vw - 725px); */
  position: relative;
  /* bottom: 30px; */
  color: yellow;
  font-weight: 900;
  display:flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: purple; */
  width: 170px;
  /* margin: 0px 1vw; */
  /* flex-grow: 1; */
}

.anotherOne{
  position: relative;
  height: 400px;
  /* background-color: blue; */
}

.profileGamespace{
  position: absolute;
  top: 300px;
  left: 35px;
  width:100px;
  height: 100px;
  border-radius: 50%;
}

.profileUsername {
  /* margin:auto; */
  /* float:center; */
  /* position:relative;
  top:10px;
  font-size: 30px;
  margin-bottom: 20px; */
  position: relative;
  top: 10px;
  left: -50px;
  font-size: 30px;
  width: 270px;
  /* display: block;
  text-align: center; */
  display:flex;
  flex-direction: row;
  justify-content: center;
  /* margin-bottom: 20px; */
}

.profileAddy {
  /* position:relative; */
  /* top:205px; */
  /* font-size: 21px; */
  /* margin-bottom: 20px; */
  position: relative;
    top: 20px;
    left: 0px;
    width:170px;
    font-size: 21px;
    text-align: center;
}

.profilePoints {
  font-size: 30px;
  margin-bottom: 20px;
  position:relative;
  font-weight: 900;
}

.yellowHome {
  color: yellow;
  font-size: 30px;
  position: absolute;
  left: 25px;
  top: 20px;
  cursor: pointer;
}

.yellowHome:hover {
  color: red;
}

.profileButton {
  color: yellow;
  font-size: 33px;
  position: relative;
  margin-right: 10px;
  top: 5px;
  cursor: pointer;
  background-color: rgba(1, 1, 1, 0);
  border: none;
  font-family: "Balsamiq Sans", sans-serif;
  float:left;
}

.profilePicInButton {
  position: relative;
  margin-right: 10px;
  float:left;
  width:36px;
  height:36px;
  border-radius: 18px;
}

.profileButton:hover {
  /* top:0px; */
  color: red;
  /* font-size: 42px; */
}






.popup {
  /* display: none; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  z-index: 1000;
  border-radius: 10px;
}

.overlay {
  /* display: none; */
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}

.popup h2 {
  margin-top: 0;
}

.popup form {
  display: flex;
  flex-direction: column;
}

.popup input {
  margin: 10px 0;
  padding: 5px;
}

.popup button {
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

.popup button:hover {
  background-color: #0056b3;
}


.winnerLoserDiv{
  /* height:400px; */
  width:62%;
  left:15%;
  gap:2vw;
  padding:3vw;
  border-radius: 20px;
  background-color:lightgrey;
  position:absolute;
  top: 40%;
  margin:auto;
  border:black 2px solid;
  display:flex;
  flex-direction: column;
  flex-basis:6000px;
  flex-grow: 1;
  /* box-shadow: 5px 5px 8px; */
}

.presale-page-body{
  /* height:100vh;
  width:100%; */
  display: flex;
  /* align-items: center; */
  /* width:100%; */
   justify-content: center;
  /* flex-wrap: wrap; */
  /* gap: 16px; */
  /* width:90%;
  margin:auto;
  top:0px;
  left:0px; */
}

.presale-container{
  /* margin:auto; */
  background-color: rgb(255, 255, 157);
  border-radius: 20px;
  border: solid black 2px;
  display: flex;
  flex-direction: row;
  flex-basis: 600px;
  /* margin-top: 100px; */
  overflow: hidden;
  align-items: flex-end;
  flex-wrap: wrap;
  gap:8px;
  padding:9px;
  /* margin: 9px; */
  /* margin-top:100px; */
  position:relative;
  font-family: "Balsamiq Sans", sans-serif;
}

.leaderboard-container{
  /* margin:auto; */
  /* background-color: rgb(255, 255, 157); */
  color: yellow;
  border-radius: 20px;
  /* border: solid black 2px; */
  display: flex;
  flex-direction: column;
  /* flex-basis: 600px; */
  font-weight: 500;
  font-size: 3vw;
  /* margin-top: 100px; */
  /* overflow: hidden; */
  align-items: center;
  /* flex-wrap: wrap; */
  /* gap:18px; */

  padding:9px;
  /* margin: 9px; */
  /* margin-top:100px; */
  position:relative;
  font-family: "Balsamiq Sans", sans-serif;
  td{
    padding: 1vw 2vw;
    /* border-bottom: 1px solid red; */
    .copyable{
      svg{
        font-size: 2vw;
      }
    }
  }
  tr{
    border-bottom: 1px solid black;
  }
}

.leaderboardUsernamePic{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.connectButtonWrapper2{
  position:absolute;
  right:10px;
  top:10px;
}

.tokenInput{
  display: flex;
  flex-grow: 1;
  flex-basis: 200px;
}

.presale-title{
  font-size:42px;
  /* color:navy; */
  /* background-color: pink; */
  width:100%;
  float: center;
  text-align: center;
}

.buyTokensButton{
  /* width:90%; */
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  height:50px;
  border-radius: 15px !important;
  background-color: red !important;
  /* flex-direction: row; */
  /* justify-content: center; */
}

.progressBar{
  height:18px !important;
  border-radius: 9px;
}

.presalePanel{
  display: flex;
  /* flex-grow:1; */
  /* background-color: pink; */
  flex-direction: column;
  width:100%;
  padding:18px;
  gap: 10px;
  font-size: 18px;
}

.right{
  float:right;
}

.left{
  float:left;
}

.orange{
  background-color: orange;
}

.presalePanelsContainer{
  display: flex;
  /* flex-grow:1; */
  flex-direction: row;
}

.topRight{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}






.main-nav {
  width: 100%;
  background-color: #0533FF;
  position: fixed;
  z-index: 500;
}

.menu-toggle {
  display: none;
  padding: 15px;
  cursor: pointer;
  float: right;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

/* Menu popup styles */
.menu-popup {
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  background-color: lightblue;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}

.menu-popup.open {
  display: block;
}

.menu-popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.menu-popup li {
  padding: 12px 16px;
  border-bottom: 1px solid #444;
}

.menu-popup li:last-child {
  border-bottom: none;
}

.menu-popup a {
  color: black;
  text-decoration: none;
  display: block;
}

.menu-popup li:hover {
  background-color: yellow;
}

.logoMobile {
  top:12px;
  position:relative;
}

/* Show the menu icon at a specific screen width */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
}

.loadingScreen{
  position:fixed;
  left:0px;
  top:0px;
  width:100vw;
  height: 100vh;
  background-color: #0533FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
}

.existingGame {
  /* height:100px; */
  overflow:hidden;
  width:180px;
  background-color: rgb(255, 255, 138);
  border-radius: 20px;
  border: solid black 1px;
  color: black;
  /* float: left; */
}

.existingGamesContainer{
  margin-top: 2vw;
  display:flex;
  position:relative;
  /* background-color:pink; */
  overflow-y:scroll;
  overflow-x: hidden;
  gap: 25px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.newGameImage{
  border-radius: 50%;
  width:90px;
  aspect-ratio: 1;
}

.newButtonsHolder{
  display: flex;
  gap: 1.5vw;
  flex-direction: row;
  justify-content: center;
}

.existingGame:hover{
  cursor:pointer;
}